import LocalStorageDBManger from "../../db/LocalStorageDBManger";
import router from "../../router";
import { gotoHomeList } from "../../views/middle-ware/utils/ControlRouter";
import GlobalHelper from "../GlobalHelper";
import userUtil from "../userUtil";

/**
 * 关闭窗口
 */
export const gotoCloseWindow = () => {
    userUtil.logout().then((res) => {
        GlobalHelper.closeWindowEvent();
    });
};

/**
 * 首页或者登录
 */
export const gotoHomeOrLogin = () => {
    userUtil.logout().then((res) => {
        const urlPath = LocalStorageDBManger.getDataWithLocalStorage("fromLogin");
        if (urlPath && urlPath.length > 0) {
            router.push(urlPath);
        } else {
            gotoHomeList({});
        }
    });
};

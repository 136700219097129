import loginhttp from "./http/loginHttp";
import store from "localforage";
import storex from "../store/store";
import Bowser from "bowser";
import localforage from "localforage";
const packageJson = require("../../package.json");
/**
 *  用户管理类
 */
//登录
export default {
    keys: {
        userData: "user_data", //所有用户信息对象
        userName: "user_name", //用户名
        token: "token",
        faceUrl: "face_url", //最新注册照地址
        orgDto: "org_dto", //最新注册照地址
    },
    browerInfo: function(param) {
        const browser = Bowser.getParser(window.navigator.userAgent);
        let result = browser.getResult();
        let browserInfo = {};
        // browserInfo.browserName = result.browser.name;
        // browserInfo.browserVersion = result.browser.version;
        // browserInfo.browserEngine = result.engine.name;
        // browserInfo.platform = result.platform.type;
        // browserInfo.osName = result.os.name;
        // browserInfo.osVersion = result.os.versionName;
        // browserInfo.osDpi = window.screen.width + "x" + window.screen.height;
        // lzb 新增版本号 2020-08-20
        browserInfo.systemVersion = `PC现场学习(${packageJson.version})`;
        /*http.get("https://ifconfig.co/ip").then(ipInfo => {
            browserInfo.ipAddr = ipInfo;
            console.log(ipInfo);
        });*/

        param = Object.assign(param, browserInfo);
        return param;
    },
    async freshUserData() {
        if (!storex.state.app.userInfo || storex.state.app.userInfo.length === 0 || !storex.state.app.userInfo["id"]) {
            return await localforage.getItem("user_data").then((data) => {
                if (data != null && data !== undefined) {
                    storex.dispatch("setUserInfo", data);
                }
            });
        }
    },
    // 从本地刷新数据到状态中
    async addUserData() {
        return await this.freshUserData();
    },
    // 清除所有本地数据
    removeUserData: async function(callback) {
        store.removeItem(this.keys.faceUrl);
        store.removeItem(this.keys.token);
        store.removeItem(this.keys.userData);
        store.removeItem(this.keys.userName);
        store.removeItem(this.keys.orgDto);
        store.removeItem(this.keys.token, (res) => {
            storex.dispatch("setUserInfo", null);

            if (callback) {
                callback();
            }
        });
    },
    // 获取当前登陆用户信息
    getCurrUser: function(val) {
        let param = val || this.keys.userData;
        return store
            .getItem(param)
            .then((userData) => {
                return userData;
            })
            .catch((err) => {
                console.error("获取用户信息错误!", err);
                return null;
            });
    },
    __loginData: function(res) {
        let _this = this;
        return new Promise((resolve, reject) => {
            let userData = res.data;
            storex.dispatch("setUserInfo", userData);
            // 保存用户的token
            if (!!userData.token) {
                localStorage.setItem("token", userData.token);
            }
            // 有点危险的操作,
            store.setItem(_this.keys.orgDto, userData.orgDto);
            store.setItem(_this.keys.userName, userData.userName);
            store.setItem(_this.keys.userData, userData);
            store.setItem(_this.keys.faceUrl, userData.faceUrl);
            store.setItem(_this.keys.token, userData.token, () => {
                resolve(res);
            });
        }).catch((err) => {});
    },
    offlineLogin: function(param) {
        let _this = this;
        window.fisrtLogined = true;
        return new Promise(function(resolve, reject) {
            param = _this.browerInfo(param);

            // 登陆, 模拟获取数据
            loginhttp
                .offlineLogin(param)
                .then((res) => {
                    if (res.success) {
                        _this.__loginData(res).then(() => {
                            resolve(res);
                        });
                    } else {
                        resolve(res);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    logout: function() {
        let _this = this;
        return new Promise((resolve, reject) => {
            this.getCurrUser(this.keys.userName)
                .then((userName) => {
                    let userLogout = {};
                    userLogout.userName = userName;
                    userLogout.userName = userName;
                    userLogout.userName = userName;
                    return userLogout;
                })
                .then((userLogout) => {
                    userLogout = _this.browerInfo(userLogout);
                    loginhttp
                        .logout(userLogout)
                        .then((res) => {
                            // 退出成功
                            store.removeItem(_this.keys.token);
                            store.removeItem(_this.keys.userData);
                            store.removeItem(_this.keys.userName);
                            store.removeItem(_this.keys.orgDto);
                            resolve(res);
                        })
                        .catch((error) => {
                            // 退出失败
                            reject(error);
                        });
                });
        });
    },
};

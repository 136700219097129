import Vue from "vue";
import WindowTimerDialog from "./WindowTimerDialog.vue";

const WindowTimerDialogExtend = Vue.extend(WindowTimerDialog);
WindowTimerDialog.install = function(options) {
    // 1. 示例话
    const instance = new WindowTimerDialogExtend({
        data: options,
    }).$mount();

    // 2.增加组件到出口
    document.body.appendChild(instance.$el);

    return instance;
};
export default WindowTimerDialog;

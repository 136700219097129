<template>
    <transition name="timer">
        <div v-if="visible" class="w-timer-alert-dialog" @click.self="onCloseAlert">
            <div class="custom-alert-content">
                <div class="c-title">温馨提示</div>
                <div class="c-message">
                    <i class="el-icon-warning"></i>
                    <span>{{ options.message }}</span>
                </div>
                <div class="c-buttons">
                    <el-button size="mini" @click="onCloseAlert">{{ getCancelButtonText }}</el-button>
                    <el-button size="mini" type="primary" @click="onConfirmAlert">{{ options.confirmButtonText }}</el-button>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    data() {
        return {
            visible: false,
            options: {
                message: "",
                seconds: 60,
                isEnabledMaskEvent: false,
                cancelButtonText: `关闭窗口`,
                confirmButtonText: "重新登录",
            },
            resolve: null,
            reject: null,
            intervalId: null,
        };
    },
    computed: {
        getCancelButtonText() {
            if (this.options.seconds > 0) {
                return `${this.options.seconds}s之后${this.options.cancelButtonText}`;
            } else {
                return this.options.cancelButtonText;
            }
        },
    },
    methods: {
        /**
         *  显示弹框
         * */
        showWindowTimerAlert(options) {
            const that = this;
            return new Promise((resolve, reject) => {
                that.visible = true;
                Object.assign(that.options, options);
                that.resolve = resolve;
                that.reject = reject;
                if (that.options.seconds > 0) {
                    that.startCountdown();
                }
            });
        },
        startCountdown() {
            this.clearInterval();
            this.intervalId = setInterval(() => {
                this.options.seconds--;
                if (this.options.seconds <= 0) {
                    this.onCloseAlert();
                }
            }, 1000);
        },

        /**
         * 关闭弹框
         */
        onCloseAlert() {
            this.visible = false;
            this.clearInterval();
            this.reject && this.reject();
        },
        /**
         * 确认弹框
         */
        onConfirmAlert() {
            this.visible = false;
            this.clearInterval();
            this.resolve && this.resolve();
        },
        /**
         * 清除定时器
         */
        clearInterval() {
            this.intervalId && clearInterval(this.intervalId);
        },
    },
};
</script>

<style lang="scss">
// 渐变过渡
.timer-enter-active,
.timer-leave-active {
    transition: opacity 0.5s;
}
.timer-enter-from,
.timer-leave-to {
    opacity: 0;
}

.w-timer-alert-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    .custom-alert-content {
        background: white;
        padding: 15px 20px;
        border-radius: 5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
        width: 300px;

        .c-title {
            font-size: 16px;
        }
        .c-message {
            margin-top: 10px;
            font-size: 14px;
            .el-icon-warning {
                color: #e6a23c;
                margin-right: 5px;
            }
        }
        .c-buttons {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
        }
        .el-button {
            margin-left: 10px;
        }
    }
}
</style>
